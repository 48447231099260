



























import { defineComponent, reactive } from "@vue/composition-api";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      error: false,
      errorMessage: "" as any,
      showPassword: false,
    });

    const model = reactive({
      email: "",
      password: "",
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 402:
          return `${root.$tc("layout.errors.securityNotAllowed")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      state.loading = true;

      root.$store
        .dispatch("user/login", {
          email: model.email,
          password: model.password,
        })
        .then(() => {
          state.error = false;
          root.$router.push(
            (root.$route.query as { next: string }).next || { name: "panel" }
          );

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.login.success"),
          });
        })
        .catch((error) => {
          state.error = true;
          if (!error.response) {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: root.$tc("layout.login.serverError"),
            });
          } else {
            state.errorMessage = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.errorMessage),
            });
          }
        })
        .finally(() => (state.loading = false));
    };

    return { state, model, onSubmit };
  },
});
